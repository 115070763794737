import { createAction, props } from '@ngrx/store';
import { PrioritizedLeadRecordDetails } from 'common.interfaces';

export enum PriorityLeadsActionTypes {
  SET_PRIORITIZED_LEADS = '[Top Priority Work] Set Prioritized Leads',
  SET_LOADING_LEADS_STATUS = '[Top Priority Work] Set Loading Leads Status',
  SET_MUTE_STATE = '[Top Priority Work] Set Mute State',
  SET_ACTIVE_STATE = '[Top Priority Work] Set Active State',
}

const setPrioritizedLeads = createAction(
  PriorityLeadsActionTypes.SET_PRIORITIZED_LEADS,
  props<{ leadsDetails: PrioritizedLeadRecordDetails[] }>(),
);
const setIsLoadingPrioritizedLeads = createAction(
  PriorityLeadsActionTypes.SET_LOADING_LEADS_STATUS,
  props<{ loadingPrioritizedLeads: boolean }>(),
);

const setMuteState = createAction(PriorityLeadsActionTypes.SET_MUTE_STATE, props<{ muted: boolean }>());
const setActiveState = createAction(PriorityLeadsActionTypes.SET_ACTIVE_STATE, props<{ active: boolean }>());
export const priorityLeadsActions = {
  setPrioritizedLeads,
  setIsLoadingPrioritizedLeads,
  setMuteState,
  setActiveState,
};
