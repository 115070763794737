import { createSelector } from '@ngrx/store';
import { Protec8State, topPriorityWorkStoreKey } from '../../../app.interface';

export const selectPriorityLeadsState = (state: Protec8State) => state[topPriorityWorkStoreKey];

export const selectPriorityLeads = createSelector(selectPriorityLeadsState, (state) => {
  return state.prioritizedLeads;
});

export const selectLeadsLoadingStatus = createSelector(selectPriorityLeadsState, (state) => {
  return state.loadingPrioritizedLeads;
});

export const selectTopPriorityMuteState = createSelector(selectPriorityLeadsState, (state) => {
  return state.muted;
});

export const selectTopPriorityActiveState = createSelector(selectPriorityLeadsState, (state) => {
  return state.active;
});
