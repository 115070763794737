import { INSURANCE_PROVIDERS_FULLNAME, InsuranceProvider_FullName, ProductSFCode } from 'common.interfaces';
import { PrioritizedLeadCalculated, PrioritizedLeadRecordDetails } from 'common.interfaces';
import { DateTime, Duration, DurationObjectUnits } from 'luxon';
import { GOLDEN_TIME_THRESHOLD } from '../app.interface';

const _SECONDS_IN_1MINUTE = 60;

export const shortenCarrierName = (carrierFullName: InsuranceProvider_FullName): string => {
  const insuranceProviderFullName = INSURANCE_PROVIDERS_FULLNAME[INSURANCE_PROVIDERS_FULLNAME.indexOf(carrierFullName)];
  switch (insuranceProviderFullName) {
    case InsuranceProvider_FullName.COL_PEN:
      return 'CNO';
    case InsuranceProvider_FullName.PGL:
      return 'PGL';
    default:
      return carrierFullName;
  }
};

/**
 * Calculates the difference between a DateTime value and now (handles timezones).
 * @param date: DateTime
 * @returns {
 *      days: number,
 *      hours: number,
 *      minutes: number,
 *      seconds: number
 * }
 */

export const convertTimeElapsedToSeconds = (l: PrioritizedLeadCalculated): number => {
  return Duration.fromObject(l.timeElapsed as DurationObjectUnits).as('seconds');
};
export const convertSecondsToDurationObjectUnits = (s: number): DurationObjectUnits => {
  return Duration.fromMillis(s * 1000)
    .shiftTo('days', 'hours', 'minutes', 'seconds')
    .toObject();
};
export const calculateTimeElapsedSinceSubmission = (date: Date): DurationObjectUnits => {
  const timeElapsedInSeconds = DateTime.fromJSDate(date).diffNow(['days', 'hours', 'minutes', 'seconds']).as('seconds');
  return convertSecondsToDurationObjectUnits(-timeElapsedInSeconds);
};
export const calculateTimeLeftTillGoldenTimeEnd = (lead: PrioritizedLeadCalculated): DurationObjectUnits => {
  return Duration.fromMillis(
    (GOLDEN_TIME_THRESHOLD.END_IN_MINUTES * _SECONDS_IN_1MINUTE - convertTimeElapsedToSeconds(lead)) * 1000,
  )
    .shiftTo('minutes', 'seconds', 'milliseconds')
    .toObject();
};

export const calculatePriorityLeadsTimes = (leads: PrioritizedLeadCalculated[]): PrioritizedLeadCalculated[] => {
  return leads.map((record) => {
    const timeElapsed = calculateTimeElapsedSinceSubmission(record.createdOn);
    const timeTillGoldenTimeEnds = calculateTimeLeftTillGoldenTimeEnd({ ...record, timeElapsed: timeElapsed });
    const isInGoldenTimeValue = isLeadInGoldenTime({ ...record, timeElapsed: timeElapsed });
    return {
      ...record,
      timeElapsed: timeElapsed,
      remainingTime: timeTillGoldenTimeEnds,
      isInGoldenTime: isInGoldenTimeValue,
    };
  });
};

/**
 * Sorts priotized leads based on three condition
 * (From more important to less import):
 *  -Is the lead is in Golden Time
 *  -Is the lead is not from Colonial Penn
 *  -Is the lead is from Colonial Penn
 * @param {PrioritizedLeadRecordDetails[]} array
 * @returns {PrioritizedLeadRecordDetails[]}
 */
export const sortLeadsByTimeElapsed = (array: PrioritizedLeadRecordDetails[]): PrioritizedLeadRecordDetails[] => {
  const arraywithTimeElapsed = [...array].map((record) => {
    if (record.createdOn) {
      return { ...record, timeElapsed: calculateTimeElapsedSinceSubmission(record.createdOn) };
    }
    return record;
  });

  return arraywithTimeElapsed.sort((a, b) => {
    const firstLeadIsFromColPen = isLeadColPen(a);
    const secondLeadIsFromColPen = isLeadColPen(b);
    const firstLeadIsInGoldenTime = isLeadInGoldenTime(a);
    const secondLeadIsInGoldenTime = isLeadInGoldenTime(b);
    const firstLeadConvertedTimeElapsedToSeconds = convertTimeElapsedToSeconds(a);
    const secondLeadConvertedTimeElapsedToSeconds = convertTimeElapsedToSeconds(b);
    const firstLeadTimeElapsedSinceEndOfGoldenTime = timeBetweenLeadCreatioTimeAndGoldenTimeEnd(a);
    const secondLeadTimeElapsedSinceEndOfGoldenTime = timeBetweenLeadCreatioTimeAndGoldenTimeEnd(b);
    const bothLeadsAreNotInGoldenTime = !firstLeadIsInGoldenTime && !secondLeadIsInGoldenTime;
    /* Position the leads in golden time */
    if (firstLeadIsInGoldenTime && !secondLeadIsInGoldenTime) {
      return -1;
    } else if (!firstLeadIsInGoldenTime && secondLeadIsInGoldenTime) {
      return 1;
    } else if (
      firstLeadIsInGoldenTime &&
      secondLeadIsInGoldenTime &&
      firstLeadConvertedTimeElapsedToSeconds === secondLeadConvertedTimeElapsedToSeconds
    ) {
      return 0;
    }
    /* Position the leads that are not CNO before the CNO leads */
    if (bothLeadsAreNotInGoldenTime) {
      if (!firstLeadIsFromColPen && secondLeadIsFromColPen) {
        return -1;
      } else if (firstLeadIsFromColPen && !secondLeadIsFromColPen) {
        return 1;
      } else {
        if (firstLeadTimeElapsedSinceEndOfGoldenTime < secondLeadTimeElapsedSinceEndOfGoldenTime) {
          return -1;
        } else if (firstLeadTimeElapsedSinceEndOfGoldenTime > secondLeadTimeElapsedSinceEndOfGoldenTime) {
          return 1;
        } else {
          return 0;
        }
      }
    }
    return secondLeadConvertedTimeElapsedToSeconds - firstLeadConvertedTimeElapsedToSeconds;
  });
};

export const isLeadInGoldenTime = (lead: PrioritizedLeadCalculated): boolean => {
  const GOLDEN_TIME_START_IN_MINUTES = GOLDEN_TIME_THRESHOLD.START_IN_MINUTES;
  const GOLDEN_TIME_END_IN_MINUTES = GOLDEN_TIME_THRESHOLD.END_IN_MINUTES;
  const _SECONDS_IN_1MINUTE = 60;

  const isAfter15min = convertTimeElapsedToSeconds(lead) >= GOLDEN_TIME_START_IN_MINUTES * _SECONDS_IN_1MINUTE;
  const isBelow60min = convertTimeElapsedToSeconds(lead) <= GOLDEN_TIME_END_IN_MINUTES * _SECONDS_IN_1MINUTE;
  return isAfter15min && isBelow60min;
};

export const isLeadColPen = (lead: PrioritizedLeadCalculated): boolean =>
  lead.carrierFromAd === InsuranceProvider_FullName.COL_PEN;

export const timeBetweenLeadCreatioTimeAndGoldenTimeEnd = (lead: PrioritizedLeadCalculated): number => {
  const leadTimeElapsedSinceCreationToSecond = convertTimeElapsedToSeconds(lead);
  const endOfGoldenTimeToSecond = GOLDEN_TIME_THRESHOLD.END_IN_MINUTES * 60;
  return Math.abs(leadTimeElapsedSinceCreationToSecond - endOfGoldenTimeToSecond);
};

const calculatePastGoldenTime = (lead: PrioritizedLeadCalculated): DurationObjectUnits => {
  const timeElapsed = Duration.fromObject(lead.timeElapsed as DurationObjectUnits).toMillis();
  return Duration.fromMillis(timeElapsed - GOLDEN_TIME_THRESHOLD.END_IN_MINUTES * 60 * 1000)
    .shiftTo('days', 'hours', 'minutes', 'seconds', 'milliseconds')
    .toObject();
};

export const convertProductName = (productAbbreviation: string): string => {
  const productCode =
    Object.values(ProductSFCode)[Object.values(ProductSFCode).indexOf(productAbbreviation as ProductSFCode)];
  switch (productCode) {
    case ProductSFCode.CRITICAL_ILLNESS:
      return 'Critical Illness';
    case ProductSFCode.FINAL:
      return 'Final Expense';
    case ProductSFCode.IUL:
      return 'IUL';
    case ProductSFCode.TERM_19:
      return 'Term';
    case ProductSFCode.DISABILITY_INCOME:
      return 'Disability Income';
    default:
      return productAbbreviation;
  }
};

export const calculateImgAttributes = (
  pathToGoldenLeadIcon: string,
  pathToLeadPastTimeIcon: string,
  pathToNewLeadIcon: string,
  lead: PrioritizedLeadCalculated,
): { src: string; title: string } => {
  const timePastGoldenTime = calculatePastGoldenTime(lead);
  if (lead.isInGoldenTime) {
    return { src: pathToGoldenLeadIcon, title: 'Golden Lead' };
  } else if (lead.timeElapsed !== undefined && Duration.fromObject(timePastGoldenTime).toMillis() > 0) {
    return { src: pathToLeadPastTimeIcon, title: 'Lead Past Golden Time' };
  } else return { src: pathToNewLeadIcon, title: 'New Lead' };
};

export const getTimeAgoText = (lead: PrioritizedLeadCalculated): string => {
  if (lead.timeElapsed) {
    const timeElapsed = Duration.fromObject(lead.timeElapsed as DurationObjectUnits).toMillis();
    const timePastGoldenTime = calculatePastGoldenTime(lead);
    let prefix;
    if (timePastGoldenTime.days !== undefined && timeElapsed > 3600 * 1000) {
      if (timePastGoldenTime.days > 0) {
        prefix = `${timePastGoldenTime.days}d ${timePastGoldenTime.hours}h ${timePastGoldenTime.minutes}m`;
      } else if (timePastGoldenTime.hours && timePastGoldenTime.hours > 0) {
        prefix = `${timePastGoldenTime.hours}h ${timePastGoldenTime.minutes}m`;
      } else if (timePastGoldenTime.minutes && timePastGoldenTime.minutes > 0) {
        prefix = `${timePastGoldenTime.minutes}m`;
      } else prefix = `${timePastGoldenTime.seconds}s`;
      return `${prefix} past time`;
    } else {
      if (lead.timeElapsed.days) {
        prefix = `${lead.timeElapsed.days}d ${lead.timeElapsed.hours}h ${lead.timeElapsed.minutes}m`;
      } else if (lead.timeElapsed.hours) {
        prefix = `${lead.timeElapsed.hours}h ${lead.timeElapsed.minutes}m`;
      }
      prefix = `${lead.timeElapsed.minutes}m`;
      return `submitted ${prefix} ago`;
    }
  }
  return '';
};

export const getTimeLeftText = (lead: PrioritizedLeadCalculated): string => {
  let prefix;
  if (lead.remainingTime !== undefined) {
    if (lead.remainingTime?.minutes) {
      prefix = `${lead.remainingTime.minutes} min`;
    } else prefix = `${lead.remainingTime.seconds} sec`;
  }
  return `${prefix} left`;
};

export const leadInGoldenTimeLabelColor = (lead: PrioritizedLeadCalculated): string => {
  if (lead.isInGoldenTime && lead.remainingTime?.minutes !== undefined) {
    if (lead.remainingTime?.minutes >= 0 && lead.remainingTime?.minutes < 5) {
      return 'less-than-5-min-left';
    } else if (lead.remainingTime?.minutes >= 5 && lead.remainingTime?.minutes < 15) {
      return 'between-5-and-15-min-left';
    } else if (
      lead.remainingTime?.minutes >= 15 &&
      lead.remainingTime?.minutes < GOLDEN_TIME_THRESHOLD.END_IN_MINUTES
    ) {
      return 'more-than-15-min-left';
    }
  }
  return '';
};
